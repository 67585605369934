body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-header {
  display: block;
  margin: 30px 0 10px;
  text-align: center;
}
.app-logo {
  width: 100px;
  height: 100px;
}

.root-container {
  background: #eee;
}

.root-container .ant-layout-header {
  background: #0c6;
}

.ant-layout-content {
  padding: 16px;
  /* margin: 16px; */
  padding-bottom: 0;
  background: #fff;
}

.cont-item a {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.ant-layout-footer {
  text-align: center;
}

.sub-title {
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

.tag-container {
  display: flex;
  padding-top: 9px;
  flex-direction: row;
  flex-wrap: wrap;
}
.tag-container > div {
  margin-left: 9px;
  margin-bottom: 9px;
}

.custom-icon-sm {
  width: 12px;
  height: 12px;
}

.custom-icon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.custom-icon-lg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.center {
  margin: 0 auto;
  text-align: center;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
